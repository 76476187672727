.confirm-dialog {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 500px;
  min-height: 200px;
  font-family: Roboto, Helvetica, Arial, sans-serif; }

.confirm-dialog-title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-height: 50px;
  padding: 0 15px;
  font-size: 20px;
  font-weight: 500;
  border-bottom: 1px solid rgba(33, 33, 33, 0.15); }

.confirm-dialog-message {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-height: 100px;
  padding: 15px;
  font-size: 13px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  border-bottom: 1px solid rgba(33, 33, 33, 0.15); }

.confirm-dialog-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  padding: 0 15px; }

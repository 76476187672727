.dropdown {
  /* display: flex; */
  /* align-items: center; */
  position: relative;
  max-width: 90%;
  width: 100%;
  margin-bottom: 10px;
}

.dropdown-wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1000;
  width: 100%;
}

.dropdown-rapper-recall {
  /* display: flex; */
  /* align-items: center; */
  border: #175df5;
  border-radius: 5px;
  max-width: 200px;
  font-weight: #175df5;
  margin-bottom: 5px;
}

.select-recall-type {
  justify-content: center;
  max-width: 90%;
  font-weight: #175df5;
}

.select {
  color: #175df5;
}

.dd-button:hover {
  background-color: #eeeeee;
}

.dd-reload {
  position: absolute;
  top: 0px;
  right: -55px;
  height: 44px;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 8px;
}

.option-label {
  font-weight: bold;
  color: #333;
}

.option-sites {
  color: #999;
  margin-left: 4px;
}
.dd-reload:hover {
  cursor: pointer;
}

.dd-reload:hover > svg {
  font-size: 2rem;
}

.site,
.airqloud {
  display: flex;
  align-items: left;
  cursor: pointer;
  padding: 5px;
  justify-content: space-between;
}

.long_name {
  color: #0560c9;
  font-size: 1rem;
  font-weight: bold;
}

.airqloud .name {
  color: #000000;
  font-size: 1rem;
  font-weight: bold;
}

.airqloud .count {
  color: #000;
  font-size: 1rem;
  /* font-weight: bold; */
}

.site-count {
  color: #008000;
  font-size: 1rem;
  /* font-weight: bold; */
}

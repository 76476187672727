.overlay-map-container {
  width: 100%;
  height: calc(100vh - 64px);
  height: -moz-calc(100vh - 64px);
  height: -webkit-calc(100vh - 64px);
  position: relative;
}

.map-new-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.map-circular-loader {
  position: absolute;
  z-index: 120;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(1px);
}

.map-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  height: 500px;
  margin: 2vh 0;
}

.map-item {
  height: 100%;
  width: 49%;
  /*max-width: 580px;*/
  position: relative;
}

.marker {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  background: blue;
  /* width: 12px;
  height: 12px; */
  cursor: pointer;
  color: #f3f3f3;
  font-weight: 600;
  border-radius: 50% 50% 50% 0;
  border: 1px solid black;
}

.marker-grey {
  background: #bcbcbc;
  color: #4b4747;
}

.marker-good {
  background: #44e527;
  /* box-shadow: 0 0 2px 2px #7eca71; */
}

.marker-moderate {
  background: #f8fe39;
  color: #4b4747;
  /* box-shadow: 0 0 2px 2px #e9ec7d; */
}

.marker-uhfsg {
  background: #ee8327;
  /* box-shadow: 0 0 2px 2px #f3a662; */
}

.marker-unhealthy {
  background: #fe0023;
  /* box-shadow: 0 0 2px 2px #ec3f56; */
}

.marker-v-unhealthy {
  background: #8639c0;
  /* box-shadow: 0 0 2px 2px #8639c0; */
}

.marker-hazardous {
  background: #81202e;
  /* box-shadow: 0 0 2px 2px #81202e; */
}

.marker-unknown {
  background: #979797;
}

.map-popup {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial,
    sans-serif;
  z-index: 1000;
}

.map-popup .mapboxgl-popup-content {
  background-color: #ffffff;
  box-shadow: 0 2px 2px 0px #ccc;
  border-radius: 4px;
  max-width: 200px;
  min-width: 160px;
}

.data-source {
  font-size: 11px;
  color: #363434;
  font-weight: 500;
  line-height: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-style: italic;
  margin-top: 5px;
}

.popup-body {
  display: flex;
  flex-flow: column nowrap;
  padding: 0;
}

.popup-title {
  text-transform: capitalize;
  font-size: 12.5px;
  letter-spacing: 0.5px;
  line-height: 15px;
  margin-bottom: 5px;
}

.popup-aqi {
  padding: 10px;
  margin-top: 6px;
  margin-bottom: 10px;
  color: black;
  border-radius: 4px;
  box-shadow: none;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.popup-aqi-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-aqi span {
  font-size: 20px;
  font-weight: 600;
  color: #4b4747;
}

.popup-aqi span sub {
  font-size: 11px;
}

.popup-pollutant {
  display: flex;
  padding: 5px;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  width: 100%;
}

.popup-pollutant .popup-pollutant-title {
  font-size: 15px;
  letter-spacing: 0.1px;
  color: rgb(85, 79, 79);
  font-weight: 600;
  line-height: 10px;
  font-style: italic;
}

.popup-pollutant .popup-pollutant-noData {
  font-size: 14px;
  letter-spacing: 0.1px;
  color: rgb(85, 79, 79);
  font-weight: 600;
  line-height: 12px;
}

.popup-pollutant .popup-pollutant-value {
  font-size: 15px;
  font-weight: 600;
  color: #363434;
}

.popup-aqi .pollutant-info {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 5px;
  width: 100%;
}

.popup-aqi .pollutant-info .pollutant-info-row {
  display: flex;
}

.popup-aqi .pollutant-info .pollutant-number {
  font-size: 18px;
  letter-spacing: 0.5px;
  color: #363434;
  font-weight: 500;
}

.popup-aqi .pollutant-info .popup-measurement {
  font-size: 10px;
  color: #363434;
}

.popup-aqi .pollutant-info .pollutant-desc {
  font-size: 10px;
  letter-spacing: 0.1px;
  color: rgb(85, 79, 79);
  font-weight: 500;
  line-height: 10px;
  font-style: italic;
}

.popup-table {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial,
    sans-serif;
  background-color: #d7dee2;
}

.popup-table tr {
  padding: 20px;
  border: 1px solid rgb(243, 226, 226);
}

.popup-table tr b {
  padding: 5px;
  font-size: 11.5px;
  letter-spacing: 0.2px;
}

.popup-table tr td:nth-child(2) {
  font-size: 15px;
  font-weight: 600;
  font-style: italic;
}

.map-toggle-controller {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px 5px;
  background: transparent;
  width: 100px;
  z-index: 100;
  border-radius: 5px;
  cursor: pointer;
  text-transform: capitalize;
  font-size: 1.05rem;
}

.control-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  margin: 5px;
  background: #ffffff;
  border-radius: 5px;
  padding: 5px;
}

.control-item-disabled {
  background: #678091;
  color: white;
}

.control-item-disabled:hover {
  background: #516573;
  color: white;
}

.custom-map-control {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 80px;
  margin: 20px;
}

.pollutant-selector {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 5px;
  width: 44px;
  height: 44px;
  background: #0078ff;
  color: #ffffff;
  font-weight: bold;
  border-radius: 50%;
  transition: all 0.2s ease 0s;
}

.map-settings {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 5px;
  width: 44px;
  height: 44px;
  background: #ffffff;
  color: #29323d;
  font-weight: bold;
  border-radius: 50%;
  transition: all 0.2s ease 0s;
}

.map-settings:hover {
  transform: scale(1.1);
}

.pollutant-selector:hover {
  transform: scale(1.1);
}

/* MAP STYLE */
.map-style {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial,
    sans-serif;
  width: auto;
  background-color: rgb(252, 241, 241);

  margin-right: 55px;
  margin-top: 8px;
  padding: 0 0 6px 3px;
  border-radius: 4px;
}

.map-icon-container:hover .change-map-mode {
  visibility: visible;
  opacity: 1;
}
.map-style h4 span {
  font-size: 14px;
  line-height: 15px;
  text-align: left;
  padding-left: 5px;
  font-weight: 600;
  text-transform: uppercase;
}
.map-style .map-style-cards {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.map-style .map-style-cards div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  text-transform: capitalize;
  font-size: 14px;
  margin: 0 4px;
  padding: 4px 8px;
  font-weight: 500;
  width: 130px;
  box-shadow: 0 1px 1px 0px #ccc;
  text-align: left;
}
.map-style .map-style-cards div svg {
  height: 16px;
}
.map-style .map-style-cards div:hover {
  color: #0078ff;
  background-color: rgb(248, 239, 239);
}

.map-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  width: 44px;
  height: 44px;
  background-color: white;
  border-radius: 50%;
  transition: all 0.2s ease 0s;
}

.map-icon {
  width: 34px;
  height: 34px;
}

.map-icon-hovered {
  color: #0078ff;
  transform: scale(1.1);
}

.map-icon-container {
  position: relative;
  display: inline-flex;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  padding: 2px;
}

.change-map-mode {
  position: absolute;
  left: -75px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s ease;
}

/* tablet */
@media (max-width: 1279px) {
  .overlay-map-container {
    width: 100vw;
    height: calc(100vh - 64px);
  }
}

/* mobile */
@media (max-width: 768px) {
  .overlay-map-container {
    width: 100vw;
    height: calc(100vh - 64px);
  }

  .air-quality-description {
    display: none;
  }
}

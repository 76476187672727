/* HorizontalLoader.css */
.loader-container {
  width: 100%;
  height: 4px;
  border-radius: 4px;
  z-index: 999;
}

.loader {
  width: 0%;
  height: 100%;
  transition: width 0.5s ease-in-out;
  animation: 0.5s linear infinite;
}
